import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Component, Output, Input, NgModule } from '@angular/core';
const ScriptStore = [{
  name: 'tableau',
  src: 'https://public.tableau.com/javascripts/api/tableau-2.9.1.min.js'
}];
class ScriptService {
  scripts = {};
  constructor() {
    ScriptStore.forEach(script => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }
  load(...scripts) {
    const promises = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }
  loadScript(name) {
    return new Promise((resolve, reject) => {
      // Resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({
          script: name,
          loaded: true,
          status: 'Already Loaded'
        });
      } else {
        // Load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({
                script: name,
                loaded: true,
                status: 'Loaded'
              });
            }
          };
        } else {
          // Other browsers
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({
              script: name,
              loaded: true,
              status: 'Loaded'
            });
          };
        }
        script.onerror = error => resolve({
          script: name,
          loaded: false,
          status: 'Loaded'
        });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
  /** @nocollapse */
  static ɵfac = function ScriptService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ScriptService)();
  };
  /** @nocollapse */
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ScriptService,
    factory: ScriptService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScriptService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class VizCreateOptions {
  disableUrlActionsPopups;
  hideTabs;
  hideToolbar;
  instanceIdToClone;
  height = '100%';
  width = '100%';
  device;
  onFirstInteractive;
  onFirstVizSizeKnown;
  toolbarPosition;
}
var ToolbarPosition;
(function (ToolbarPosition) {
  ToolbarPosition["TOP"] = "top";
  ToolbarPosition["BOTTOM"] = "bottom";
})(ToolbarPosition || (ToolbarPosition = {}));
class TableauComponent {
  scriptService;
  tableauViz;
  loaded = new EventEmitter();
  tableauVizLoaded = new EventEmitter();
  tableauJsApiUrl;
  tableauVizUrl;
  serverUrl;
  ticket;
  site;
  report;
  filters;
  options;
  debugMode = false;
  constructor(scriptService) {
    this.scriptService = scriptService;
  }
  debug(message, data = {}) {
    if (this.debugMode) {
      data ? console.log(message) : console.log(message, data);
    }
  }
  ngOnInit() {
    if (this.tableauJsApiUrl) {
      // If tableauJsApiUrl is defined, load custom Tableau JavaScript API from this variable
      this.debug(`Loading custom Tableau JavaScript API file from ${this.tableauJsApiUrl}`);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.tableauJsApiUrl;
      document.getElementsByTagName('head')[0].appendChild(script);
      script.onload = () => {
        this.debug(`Custom Tableau JavaScript API successful loaded from ${this.tableauJsApiUrl}`);
        this.renderTableauViz();
        this.loaded.emit(true);
      };
      script.onerror = error => console.error('Error loading custom Tableau JavaScript API', error);
    } else {
      // If tableauJsApiUrl is not defined, load default Tableau JavaScript API from ScriptService
      this.debug(`Loading default Tableau JavaScript API file`);
      this.scriptService.load('tableau').then(data => {
        this.debug('Default Tableau JavaScript API successful loaded', data);
        this.renderTableauViz();
        this.loaded.emit(data);
      }).catch(error => console.error('Error loading default Tableau JavaScript API', error));
    }
  }
  /**
   * Render a Tableau visualization, generating Tableau URL and using Tableau JS API to show vizualization
   */
  renderTableauViz() {
    const placeholderDiv = document.getElementById('tableauViz');
    const options = {
      ...this.options,
      ...this.filters
    };
    if (this.checkRequiredInputs()) {
      // Usage of Tableau JS API to show visualization
      this.tableauViz = new tableau.Viz(placeholderDiv, this.tableauVizUrl, options);
      this.tableauVizLoaded.emit(this.tableauViz);
    }
  }
  /**
   * Check if all required inputs for embedding a Tableau visualization are set
   * @returns true if all required inputs are set, false otherwise
   */
  checkRequiredInputs() {
    if (!this.tableauVizUrl) {
      return this.createUrlFromInputs();
    } else {
      this.debug(`Using Tableau visualization URL: ${this.tableauVizUrl}`);
    }
    return true;
  }
  multisiteUrlOrNot() {
    if (this.site) {
      return `/t/${this.site}/views/${this.report}`;
    } else {
      return `/views/${this.report}`;
    }
  }
  createUrlFromInputs() {
    if (this.ticket && this.serverUrl && this.report) {
      const endOfUrl = this.multisiteUrlOrNot();
      this.tableauVizUrl = `${this.serverUrl}/trusted/${this.ticket}${endOfUrl}`;
      this.debug(`Using Tableau visualization URL for private site: ${this.tableauVizUrl}`);
      return true;
    } else if (this.serverUrl && this.report) {
      const endOfUrl = this.multisiteUrlOrNot();
      this.tableauVizUrl = `${this.serverUrl}${endOfUrl}`;
      this.debug(`Using Tableau visualization URL for public site: ${this.tableauVizUrl}`);
      return true;
    } else {
      console.error('One or both of the following parameters are missing: serverUrl or report');
      return false;
    }
  }
  ngOnDestroy() {
    // Dispose tableauViz to avoid memory leaks when component is destroyed
    if (this.tableauViz) {
      this.tableauViz.dispose();
    }
  }
  /** @nocollapse */
  static ɵfac = function TableauComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TableauComponent)(i0.ɵɵdirectiveInject(ScriptService));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: TableauComponent,
    selectors: [["ngx-tableau"]],
    inputs: {
      tableauJsApiUrl: "tableauJsApiUrl",
      tableauVizUrl: "tableauVizUrl",
      serverUrl: "serverUrl",
      ticket: "ticket",
      site: "site",
      report: "report",
      filters: "filters",
      options: "options",
      debugMode: "debugMode"
    },
    outputs: {
      loaded: "loaded",
      tableauVizLoaded: "tableauVizLoaded"
    },
    standalone: false,
    decls: 1,
    vars: 0,
    consts: [["id", "tableauViz", 1, "ngx-tableau-viz"]],
    template: function TableauComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelement(0, "div", 0);
      }
    },
    styles: [".ngx-tableau-viz[_ngcontent-%COMP%]{z-index:1;height:calc(100vh - 20px)}"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableauComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-tableau',
      template: ` <div class="ngx-tableau-viz" id="tableauViz"></div> `,
      styles: [".ngx-tableau-viz{z-index:1;height:calc(100vh - 20px)}\n"]
    }]
  }], function () {
    return [{
      type: ScriptService
    }];
  }, {
    loaded: [{
      type: Output
    }],
    tableauVizLoaded: [{
      type: Output
    }],
    tableauJsApiUrl: [{
      type: Input
    }],
    tableauVizUrl: [{
      type: Input
    }],
    serverUrl: [{
      type: Input
    }],
    ticket: [{
      type: Input
    }],
    site: [{
      type: Input
    }],
    report: [{
      type: Input
    }],
    filters: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    debugMode: [{
      type: Input
    }]
  });
})();
class TableauModule {
  /** @nocollapse */static ɵfac = function TableauModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TableauModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TableauModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [ScriptService]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableauModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [TableauComponent],
      providers: [ScriptService],
      exports: [TableauComponent]
    }]
  }], null, null);
})();
var TableauEvents;
(function (TableauEvents) {
  TableauEvents["CUSTOM_MARK_CONTEXT_MENU"] = "custommarkcontextmenu";
  TableauEvents["CUSTOM_VIEW_LOAD"] = "customviewload";
  TableauEvents["CUSTOM_VIEW_REMOVE"] = "customviewremove";
  TableauEvents["CUSTOM_VIEW_SAVE"] = "customviewsave";
  TableauEvents["CUSTOM_VIEW_SET_DEFAULT"] = "customviewsetdefault";
  TableauEvents["FILTER_CHANGE"] = "filterchange";
  TableauEvents["FIRST_INTERACTIVE"] = "firstinteractive";
  TableauEvents["FIRST_VIZ_SIZE_KNOWN"] = "firstvizsizeknown";
  TableauEvents["MARKS_SELECTION"] = "marksselection";
  TableauEvents["MARKS_HIGHLIGHT"] = "markshighlight";
  TableauEvents["PARAMETER_VALUE_CHANGE"] = "parametervaluechange";
  TableauEvents["STORY_POINT_SWITCH"] = "storypointswitch";
  TableauEvents["TAB_SWITCH"] = "tabswitch";
  TableauEvents["TOOLBAR_STATE_CHANGE"] = "toolbarstatechange";
  TableauEvents["URL_ACTION"] = "urlaction";
  TableauEvents["VIZ_RESIZE"] = "vizresize";
})(TableauEvents || (TableauEvents = {}));

/*
 * Public API Surface of tableau
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TableauComponent, TableauEvents, TableauModule, ToolbarPosition, VizCreateOptions };
